import {Box, Theme} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import React, {useEffect} from "react"
import Alert, {AlertProps} from "./Alert"
import useEditor from "../hooks/useEditor"

export interface ControlledPreviewProps {
  alert?: AlertProps
  input?: string
}

const useStyles = makeStyles<Theme>(() => ({
  root: {
    position: "relative",
    height: "100%"
  },
  layout: {
    position: "relative",
    height: "100%",
    overflow: "auto",
    "& > div": {
      padding: "1rem"
    }
  },
  footer: {
    position: "absolute",
    height: "4rem",
    bottom: 0,
    left: "50%",
    transform: "translateX(-50%)",
    padding: "0.75rem 1rem",
    display: "flex",
    alignItems: "center"
  }
}))

export default function ControlledPreview({
  input = " ",
  ...props
}: ControlledPreviewProps) {
  const s = useStyles()

  const {editorRef, editorView} = useEditor({
    lang: "js",
    input: input,
    editable: false,
    lineNumbers: false
  })

  useEffect(() => {
    if (editorView.current) {
      editorView.current.dispatch({
        changes: {
          from: 0,
          to: editorView.current.state.doc.length,
          insert: input || " "
        }
      })
    }
  }, [editorView.current, input])

  return (
    <Box className={s.root}>
      <Box className={s.layout}>
        <Box ref={editorRef}/>
      </Box>
      {props?.alert && (
        <Box className={s.footer}>
          <Alert {...props.alert}/>
        </Box>
      )}
    </Box>
  )
}
