import {
  autocompletion,
  closeBrackets,
  closeBracketsKeymap,
  completionKeymap
} from "@codemirror/autocomplete"
import {
  bracketMatching,
  defaultHighlightStyle,
  foldKeymap,
  indentOnInput,
  syntaxHighlighting
} from "@codemirror/language"
import {searchKeymap} from "@codemirror/search"
import {useEffect, useRef, useState} from "react"
import {EditorState, StateEffect} from "@codemirror/state"
import {
  EditorView,
  crosshairCursor,
  drawSelection,
  dropCursor,
  keymap,
  lineNumbers as lineNumbersExtension,
  rectangularSelection,
  highlightActiveLineGutter,
  highlightSpecialChars
} from "@codemirror/view"
import {
  defaultKeymap,
  history,
  historyKeymap,
  indentWithTab
} from "@codemirror/commands"
import getLanguageToSetup from "./helpers/getLanguageToSetup"
import getEditorTheme from "./helpers/getEditorTheme"

interface Props {
  lang?: string
  input: string
  theme?: "light" | "dark"
  highlight?: boolean
  editable?: boolean
  lineNumbers?: boolean
  onChange?: (value: string) => void
  onBlur?: () => void
}

export default function useEditor({
  input,
  lang = "",
  theme = "dark",
  highlight = true,
  editable = true,
  lineNumbers = true,
  onChange,
  onBlur
}: Props) {
  const editorRef = useRef<HTMLDivElement>(null)
  const editorView = useRef<any>(null)

  const [state, setState] = useState<EditorState | null>(null)

  useEffect(() => {
    if (!input) return

    const createSetupConfig = async () => {
      const viewTheme = getEditorTheme(theme)

      const extensions = [
        highlightActiveLineGutter(),
        highlightSpecialChars(),
        history(),
        drawSelection(),
        dropCursor(),
        EditorState.allowMultipleSelections.of(true),
        indentOnInput(),
        bracketMatching(),
        closeBrackets(),
        autocompletion(),
        rectangularSelection(),
        crosshairCursor(),
        keymap.of([
          ...defaultKeymap,
          ...closeBracketsKeymap,
          ...searchKeymap,
          ...historyKeymap,
          ...foldKeymap,
          ...completionKeymap
        ])
      ]

      if (lineNumbers) {
        extensions.unshift(lineNumbersExtension())
      }

      if (onChange) {
        extensions.push(
          EditorView.updateListener.of(e => {
            onChange(e.state.doc.toString())
          })
        )
      }

      if (onBlur) {
        extensions.push(
          EditorView.focusChangeEffect.of((_, focusing) => {
            if (!focusing) {
              onBlur()
            }

            return StateEffect.define(undefined).of(null)
          })
        )
      }

      if (highlight) {
        extensions.push(
          highlightActiveLineGutter(),
          highlightSpecialChars(),
          syntaxHighlighting(defaultHighlightStyle, {
            fallback: true
          })
        )
      }

      if (lang) {
        let langToSetup = await getLanguageToSetup(lang)

        extensions.push(langToSetup())
      }

      const eState = EditorState.create({
        doc: input,
        extensions: [
          // basicSetup,
          extensions,
          EditorView.editable.of(editable),
          EditorView.lineWrapping,
          viewTheme,
          keymap.of([
            ...defaultKeymap,
            ...closeBracketsKeymap,
            ...historyKeymap,
            ...foldKeymap,
            ...completionKeymap,
            indentWithTab
          ])
        ]
      })

      setState(eState)
    }

    createSetupConfig()
  }, [lang, editable, theme])

  useEffect(() => {
    if (!state) return

    if (!editorRef.current) return

    const view = new EditorView({
      state,
      parent: editorRef.current
    })

    editorView.current = view

    return () => {
      view.destroy()
    }
  }, [state])

  return {
    editorRef,
    editorView
  }
}
