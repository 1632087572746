import {Box, Typography} from "@mui/material"
import {makeStyles} from "@mui/styles"
import cn from "classnames"
import React from "react"
import {CodeEditorFile} from "../../../index"

interface Props {
  files: CodeEditorFile[]
  onFileClick: (file: CodeEditorFile) => void
}

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  item: {
    padding: (theme as any).spacing(1, 3),
    cursor: "pointer",
    transition: ".1s",
    "&:hover, &.active": {
      background: "#353337",
      transition: ".1s"
    }
  }
}))

export default function Files({
  files,
  onFileClick
}: Props) {
  const s = useStyles()

  return (
    <Box className={s.root}>
      {files.map(i => (
        <Box
          key={i.filename}
          className={cn(s.item, {"active": i.isCurrent})}
          onClick={() => onFileClick(i)}>
          <Typography variant="body2" color="white.main">
            {i.filename}
          </Typography>
        </Box>
      ))}
    </Box>
  )
}
