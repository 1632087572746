import {Box, Theme} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import cn from "classnames"
import {AlertProps} from "./components/Alert"
import ControlledPreview, {ControlledPreviewProps} from "./components/ControlledPreview"
import ControlledEditor, {ControlledEditorProps} from "./components/ControlledEditor"
import {OverviewProps} from "./components/Sidebar/components/Overview"
import Sidebar from "./components/Sidebar"
import Tabs from "./components/Tabs"
import Editor from "./components/Editor"
import Preview from "./components/Preview"
import React, {useEffect, useMemo, useRef, useState} from "react"

export type CodeEditorFile = {
  isOpened?: boolean
  isCurrent?: boolean
  lang?: string
  filename: string
  content: string
}

type Props = ({
  value: string
  onChange?: (value: string) => void
  onBlur?: () => void
  lang?: string
} | {
  files: CodeEditorFile[]
  iframePreview?: boolean
} | {
  vertical?: boolean
  overview?: OverviewProps
  code: Omit<ControlledEditorProps, "controlsOffset">
  alert?: AlertProps
  alertPosition?: "default" | "bottom"
  compiled: ControlledPreviewProps["input"]
  onChange?: (value: string) => void
}) & {
  hideLineNumbers?: boolean
  styles?: {
    rounded?: boolean
    height?: number | string
  }
}

const useStyles = makeStyles<Theme>(() => ({
  root: {
    position: "relative",
    width: "100%",
    background: "#282828",
    overflow: "hidden",
    "&.rounded": {
      borderRadius: "6px"
    }
  },
  layout: {
    position: "relative",
    width: "100%",
    height: "100%",
    transition: ".3s",
    "&.multifile": {
      paddingLeft: "15%"
    },
    "&.controlledEditor": {
      paddingLeft: "32%"
    },
    "&.sidebarCollapsed": {
      paddingLeft: 0,
      transition: ".3s"
    }
  },
  wrapper: {
    position: "relative",
    width: "100%",
    height: "100%",
    "&.withPreview": {
      display: "grid",
      gridTemplateColumns: "50% 50%",
      alignItems: "stretch",
      "&.vertical": {
        display: "grid",
        gridTemplateColumns: "unset",
        gridTemplateRows: "50% 50%",
        alignItems: "stretch"
      }
    }
  },
  left: {
    position: "relative",
    overflow: "auto",
    height: "100%",
    borderRight: "1px solid #353337",
    "&.vertical": {
      borderRight: "none",
      borderBottom: "1px solid #353337"
    }
  },
  editor: {
    position: "relative",
    width: "100%",
    height: "100%",
    "&.multifile": {
      height: "calc(100% - 48px)",
      padding: 0,
      top: "48px"
    }
  },
  splitter: {
    position: "absolute",
    zIndex: 99,
    width: 10,
    height: 86,
    top: "45%",
    left: "50%",
    transform: "translateX(-50%)",
    borderRadius: "8px",
    cursor: "col-resize",
    backgroundColor: "#353337",
    transition: "background-color .3s",
    "&.multifile": {
      top: "35%"
    },
    "&.vertical": {
      width: 86,
      height: 10,
      top: "50%",
      left: "50%",
      transform: "translate3d(-50%, -50%, 0)"
    },
    "&:hover": {
      backgroundColor: "#3C3C3C",
      transition: "background-color .2s"
    }
  },
  right: {
    position: "relative",
    overflow: "auto",
    height: "100%"
  }
}))

export default function CodeEditor(props: Props) {
  const s = useStyles()

  const splitterRef = useRef<HTMLDivElement>(null)

  const [files, setFiles] = useState<CodeEditorFile[]>([])
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false)
  const [splitterIsDragging, setSplitterIsDragging] = useState(false)

  const config = useMemo(() => {
    return {
      value: "value" in props ? props.value : undefined,
      onChange: "onChange" in props ? props.onChange : undefined,
      onBlur: "onBlur" in props ? props.onBlur : undefined,
      lang: "lang" in props ? props.lang : undefined,
      files: "files" in props ? props.files : undefined,
      iframePreview: "iframePreview" in props ? props.iframePreview : undefined,
      vertical: "vertical" in props ? props.vertical : undefined,
      overview: "overview" in props ? props.overview : undefined,
      code: "code" in props ? props.code : undefined,
      alert: "alert" in props ? props.alert : undefined,
      alertPosition: "alertPosition" in props ? props.alertPosition : undefined,
      compiled: "compiled" in props ? props.compiled : undefined,
      hideLineNumbers: "hideLineNumbers" in props ? props.hideLineNumbers : undefined,
      styles: props.styles
    }
  }, [props])

  const isMultifile = useMemo(() => {
    return (config.files?.length || 0) > 0
  }, [config.files])

  const isControlledEditor = useMemo(() => {
    return !!config.code
  }, [config.code])

  const hasSidebar = useMemo(() => {
    return isMultifile || config.overview
  }, [isMultifile, config.overview])

  const noPreview = useMemo(() => {
    return !config.code?.onChange
  }, [config.code])

  const hasPreview = useMemo(() => {
    return !noPreview && ((isControlledEditor && (config.code || config.compiled)) || config.iframePreview)
  }, [noPreview, isControlledEditor, config.code, config.compiled, config.iframePreview])

  useEffect(() => {
    if (isMultifile) {
      setFiles(config.files!)
    } else {
      setFiles([
        {
          isOpened: true,
          isCurrent: true,
          filename: "default",
          lang: config.lang,
          content: config.value!
        }
      ])
    }
  }, [isMultifile, config.value, config.lang])

  useEffect(() => {
    if (files.filter(i => i.isOpened).length && !files.filter(i => i.isCurrent).length) {
      setFiles(current => {
        return current.map(i => {
          if (current.filter(i => i.isOpened).reverse()[0]?.filename === i.filename) {
            return {
              ...i,
              isCurrent: true
            }
          } else {
            return i
          }
        })
      })
    }
  }, [files])

  const currentFile = useMemo(() => {
    return files.find(i => i.isCurrent) || null
  }, [files])

  const handleResize = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!splitterIsDragging || !splitterRef.current) return

    const container = splitterRef.current.parentNode as HTMLDivElement

    if (config.vertical) {
      const offsetY = e.clientY - container.getBoundingClientRect().top
      const containerHeight = container.clientHeight
      const splitterPercentage = (offsetY / containerHeight) * 100

      if (splitterPercentage >= 30 && splitterPercentage <= 70) {
        splitterRef.current.style.top = `${splitterPercentage}%`
        container.style.gridTemplateRows = `${splitterPercentage}% ${100 - splitterPercentage}%`
      }
    } else {
      const offsetX = e.clientX - container.getBoundingClientRect().left
      const containerWidth = container.clientWidth
      const splitterPercentage = (offsetX / containerWidth) * 100

      if (splitterPercentage >= 30 && splitterPercentage <= 70) {
        splitterRef.current.style.left = `${splitterPercentage}%`
        container.style.gridTemplateColumns = `${splitterPercentage}% ${100 - splitterPercentage}%`
      }
    }
  }

  const handleClickFile = (
    source: "files" | "tabs",
    action: "select" | "close",
    file: CodeEditorFile
  ) => {
    if (isMultifile) {
      setFiles(current => {
        return current.map(i => {
          if (i.filename === file.filename) {
            if (source === "files" && action === "select") {
              return {
                ...i,
                isOpened: true,
                isCurrent: true
              }
            } else if (source === "tabs") {
              if (action === "select") {
                return {
                  ...i,
                  isCurrent: true
                }
              } else if (action === "close") {
                return {
                  ...i,
                  isOpened: false,
                  isCurrent: false
                }
              }
            }
          } else {
            return {
              ...i,
              isCurrent: false
            }
          }
        }) as CodeEditorFile[]
      })
    }
  }

  const handleFileChange = (filename: string, value: string) => {
    if (config.onChange) {
      config.onChange(value)
    }

    setFiles(current => {
      return current.map(i => {
        if (i.filename === filename) {
          return {
            ...i,
            content: value
          }
        } else {
          return i
        }
      })
    })
  }

  return (
    <Box
      className={cn(s.root, {
        "rounded": config.styles?.rounded
      })}
      sx={{
        height: config.styles?.height || "100%"
      }}
      onMouseUp={() => setSplitterIsDragging(false)}
      onMouseMove={handleResize}>
      {hasSidebar && (
        <Sidebar
          files={isMultifile ? files : undefined}
          overview={config.overview}
          isCollapsed={sidebarCollapsed}
          onCollapseToggle={() => setSidebarCollapsed(!sidebarCollapsed)}
          onFileClick={file => handleClickFile("files", "select", file)}
        />
      )}
      <Box
        className={cn(s.layout, {
          "multifile": isMultifile,
          "controlledEditor": isControlledEditor,
          "sidebarCollapsed": !hasSidebar || sidebarCollapsed
        })}>
        <Box
          className={cn(s.wrapper, {
            "withPreview": hasPreview,
            "vertical": config.vertical
          })}>
          {isControlledEditor ? (
            <Box className={cn(s.left, {"vertical": config.vertical})}>
              <Box className={s.editor}>
                <ControlledEditor
                  vertical={config.vertical}
                  inactive={!config.code?.onChange}
                  {...(config.code || {})}
                  onChange={v => handleFileChange("default", v)}
                  controlsOffset={hasSidebar && sidebarCollapsed}
                />
              </Box>
            </Box>
          ) : (
            <Box className={s.left}>
              {isMultifile && (
                <Tabs
                  files={files}
                  onFileClick={file => handleClickFile("tabs", "select", file)}
                  onFileClose={file => handleClickFile("tabs", "close", file)}
                />
              )}
              {currentFile && (
                <Box className={cn(s.editor, {"multifile": isMultifile})}>
                  <Editor
                    file={currentFile}
                    onChange={handleFileChange}
                    onBlur={config.onBlur}
                    hideLineNumbers={config.hideLineNumbers}
                  />
                </Box>
              )}
            </Box>
          )}
          {hasPreview && (
            <Box
              ref={splitterRef}
              className={cn(s.splitter, {
                "multifile": isMultifile,
                "vertical": config.vertical
              })}
              onMouseDown={() => setSplitterIsDragging(true)}
            />
          )}
          {hasPreview && (
            <Box
              className={cn(s.right, {"sidebarCollapsed": sidebarCollapsed})}
              sx={{
                pointerEvents: splitterIsDragging ? "none" : "unset",
                userSelect: splitterIsDragging ? "none" : "unset"
              }}>
              {(config.alert || config.compiled) ? (
                <ControlledPreview
                  alert={config.alert}
                  input={config.compiled}
                />
              ) : config.iframePreview && (
                <Preview files={files}/>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}
