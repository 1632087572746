import CloseIcon from "@mui/icons-material/Close"
import {Box, Typography} from "@mui/material"
import {makeStyles} from "@mui/styles"
import cn from "classnames"
import React from "react"
import {CodeEditorFile} from "../index"

interface Props {
  files: CodeEditorFile[]
  onFileClick: (file: CodeEditorFile) => void
  onFileClose: (file: CodeEditorFile) => void
}

const useStyles = makeStyles(theme => ({
  root: {
    position: "absolute",
    width: "100%",
    height: "48px",
    overflowY: "hidden",
    overflowX: "auto",
    borderBottom: "1px solid #353337",
    display: "flex"
  },
  item: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    padding: (theme as any).spacing(1, 1.5, 1, 3),
    cursor: "pointer",
    transition: ".1s",
    "&:hover, &.active": {
      background: "#353337",
      transition: ".1s"
    }
  },
  close: {
    position: "relative",
    zIndex: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 14,
    height: 14,
    marginLeft: 16,
    opacity: ".4",
    transition: ".1s",
    "&:hover": {
      opacity: "1",
      transition: ".1s"
    }
  }
}))

export default function Sidebar({
  files,
  onFileClick,
  onFileClose
}: Props) {
  const s = useStyles()

  return (
    <Box className={s.root}>
      {files.filter(i => i.isOpened).map(i => (
        <Box
          key={i.filename}
          className={cn(s.item, {"active": i.isCurrent})}>
          <Typography variant="body2" color="white.main">
            {i.filename}
          </Typography>
          <Box
            position="absolute"
            width="100%"
            height="100%"
            left="0"
            zIndex={10}
            onClick={() => onFileClick(i)}
          />
          <Box
            className={s.close}
            onClick={() => onFileClose(i)}>
            <CloseIcon style={{
              width: "100%",
              height: "100%",
              fill: "#fff"
            }}/>
          </Box>
        </Box>
      ))}
    </Box>
  )
}
