import {Theme} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import React from "react"
import useEditor from "../../../hooks/useEditor"

export interface OverviewProps {
  lang?: string
  input?: string
}

const useStyles = makeStyles<Theme>(() => ({
  root: {
    position: "relative",
    width: "100%",
    height: "100%",
    overflow: "auto"
  },
  layout: {
    padding: "1rem"
  }
}))

export default function Overview({
  lang = "",
  input = " "
}: OverviewProps) {
  const s = useStyles()

  const {editorRef} = useEditor({
    lang,
    input,
    lineNumbers: false,
    editable: false
  })

  return (
    <div className={s.root}>
      <div className={s.layout}>
        <div ref={editorRef}/>
      </div>
    </div>
  )
}
