import {Box, Typography} from "@mui/material"
import {makeStyles} from "@mui/styles"
import cn from "classnames"
import {debounce} from "lodash"
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react"
import {CodeEditorFile} from "../index"

interface Props {
  files: CodeEditorFile[]
}

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    width: "100%",
    height: "100%",
    display: "grid",
    gridTemplateRows: "75% 25%",
    alignItems: "stretch"
  },
  top: {
    position: "relative",
    width: "100%",
    height: "100%",
    backgroundColor: "#fff"
  },
  splitter: {
    position: "absolute",
    zIndex: 9999,
    width: 48,
    height: 10,
    top: "75%",
    left: "50%",
    transform: "translate3d(-50%, -50%, 0)",
    borderRadius: "8px",
    cursor: "row-resize",
    backgroundColor: "#353337",
    transition: "background-color .3s",
    "&:hover": {
      backgroundColor: "#3C3C3C",
      transition: "background-color .2s"
    }
  },
  bottom: {
    position: "relative",
    width: "100%",
    height: "100%",
    overflowY: "auto",
    borderTop: "1px solid #353337"
  },
  consoleItem: {
    position: "relative",
    padding: "6px 10px",
    borderBottom: "1px solid #353337",
    display: "flex",
    alignItems: "center",
    gap: "4px",
    fontSize: "14px",
    fontFamily: "Arial,sans-serif"
  },
  error: {
    backgroundColor: "#4A3635"
  },
  string: {
    color: "#E3E3E3"
  },
  number: {
    color: "#9580F7"
  },
  boolean: {
    color: "#EE9D70"
  },
  object: {
    color: "#7DD2F7"
  }
}))

export default function Preview({
  files
}: Props) {
  const s = useStyles()

  const splitterRef = useRef<HTMLDivElement>(null)

  const [iframeSrc, setIframeSrc] = useState("")
  const [logs, setLogs] = useState<Array<{
    type: "log" | "error"
    data: any[]
  }>>([])
  const [splitterIsDragging, setSplitterIsDragging] = useState(false)

  const filesMap = useMemo(() => {
    return {
      html: files.find(i => i.filename.endsWith(".html")),
      css: files.find(i => i.filename.endsWith(".css")),
      js: files.find(i => i.filename.endsWith(".js"))
    }
  }, [files])

  const handleUpdateSrc = useCallback(debounce((newValue: string) => {
    setIframeSrc(newValue)
  }, 900), [])

  useEffect(() => {
    handleUpdateSrc(`
      <html>
        <head>
          <script>
            (function() {
              var originalConsoleLog = console.log
              var originalConsoleError = console.error
              
              console.log = function() {
                originalConsoleLog.apply(console, arguments)
                window.parent.postMessage({
                  type: "consoleLog",
                  message: [...arguments]
                }, "*")
              }
              
              console.error = function() {
                originalConsoleError.apply(console, arguments)
                window.parent.postMessage({
                  type: "consoleError",
                  message: [...arguments]
                }, "*")
              }
            })()
          </script>
        </head>
        <body>${filesMap.html?.content || ""}</body>
        <style>${filesMap.css?.content || ""}</style>
        <script>
          try {
            var userCode = \`${filesMap.js?.content}\`
            var userFunction = new Function(userCode)
            
            userFunction()
          } catch (error) {
            console.error("Error:", error)
          }
        </script>
      </html>
    `)
  }, [filesMap])

  useEffect(() => {
    const handleMessage = (e: MessageEvent) => {
      if (e.data) {
        if (e.data.type === "consoleLog") {
          setLogs((current) => [...current, {
            type: "log",
            data: e.data.message
          }])
        } else if (e.data.type === "consoleError") {
          setLogs((current) => [...current, {
            type: "error",
            data: e.data.message
          }])
        }
      }
    }

    window.addEventListener("message", handleMessage)

    return () => {
      window.removeEventListener("message", handleMessage)
    }
  }, [])

  const handleResize = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!splitterIsDragging || !splitterRef.current) return

    const container = splitterRef.current.parentNode as HTMLDivElement
    const offsetY = e.clientY - container.getBoundingClientRect().top
    const containerHeight = container.clientHeight
    const splitterPercentage = (offsetY / containerHeight) * 100

    if (splitterPercentage >= 50 && splitterPercentage <= 90) {
      splitterRef.current.style.top = `${splitterPercentage}%`
      container.style.gridTemplateRows = `${splitterPercentage}% ${100 - splitterPercentage}%`
    }
  }

  return (
    <Box
      className={s.root}
      onMouseUp={() => setSplitterIsDragging(false)}
      onMouseMove={handleResize}>
      <Box
        className={s.top}
        sx={{
          pointerEvents: splitterIsDragging ? "none" : "unset",
          userSelect: splitterIsDragging ? "none" : "unset"
        }}>
        <iframe
          title="Preview"
          srcDoc={iframeSrc}
          style={{
            width: "100%",
            height: "100%",
            border: "none"
          }}
        />
      </Box>
      <Box
        ref={splitterRef}
        className={s.splitter}
        onMouseDown={() => setSplitterIsDragging(true)}
      />
      <Box
        className={s.bottom}
        sx={{
          pointerEvents: splitterIsDragging ? "none" : "unset",
          userSelect: splitterIsDragging ? "none" : "unset"
        }}>
        <Typography variant="caption" px={1} color="gray">
          Console
        </Typography>
        {logs.map((i, num) => (
          <Box key={num} className={cn(s.consoleItem, {[s.error]: i.type === "error"})}>
            {i.data.map((item, number) => (
              <Box
                key={`${num}-${number}`}
                className={cn((s as any)[typeof item])}>
                {`${item}`}
              </Box>
            ))}
          </Box>
        ))}
      </Box>
    </Box>
  )
}
