import {Box, Theme} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {useMemo} from "react"

export interface AlertProps {
  code: number
  name: string
  colors?: {[key: string]: string}
  labels?: {[key: string]: string}
}

export const defaultColors = {
  0: "#00c2ee",
  1: "#00c2ee",
  2: "#00c2ee",
  3: "#00c2ee",
  4: "#00c2ee",
  5: "#00c2ee",
  6: "#00c2ee",
  7: "#00c2ee",
  8: "#00c2ee",
  11: "#ec7b7b",
  12: "#ec7b7b",
  13: "#ec7b7b",
  14: "#ec7b7b",
  15: "#1ea46c",
  17: "#ec7b7b",
  20: "#ec7b7b"
}

const useStyles = makeStyles<Theme>(() => ({
  root: {
    padding: "6px 16px",
    textAlign: "center",
    fontFamily: "Arial, sans-serif",
    fontSize: "0.9rem",
    borderRadius: "4px",
    color: "#fff"
  }
}))

export default function Alert ({
  code,
  name,
  colors,
  labels
}: AlertProps) {
  const s = useStyles()

  const color = useMemo(() => {
    return colors?.[code] || defaultColors[code as keyof typeof defaultColors] || defaultColors[0]
  }, [colors, code])

  const label = useMemo(() => {
    return (labels && name && labels?.[code]) || name || (name === null ? "You haven`t made any submissions yet" : "compiling...")
  }, [labels, name, code])

  return (
    <Box className={s.root} sx={{backgroundColor: color || "transparent"}}>
      {label}
    </Box>
  )
}
