import singleTaskTypeIcon from "assets/icons/taskTypes/single.svg"
import multipleTaskTypeIcon from "assets/icons/taskTypes/multiple.svg"
import matchTaskTypeIcon from "assets/icons/taskTypes/match.svg"
import parsonsTaskTypeIcon from "assets/icons/taskTypes/parsons.svg"
import codeTaskTypeIcon from "assets/icons/taskTypes/code.svg"
import iframeTaskTypeIcon from "assets/icons/taskTypes/iframe.svg"

type TaskType = "single" | "multiple" | "parsons" | "match" | "wordpress" | "office365" | "code"

const taskTypes: {
  [key: string]: {
    id: TaskType
    icon: string
    note?: string
  }
} = {
  "single": {
    id: "single",
    icon: singleTaskTypeIcon,
    note: "This task has only one right answer."
  },
  "multiple": {
    id: "multiple",
    icon: multipleTaskTypeIcon,
    note: "This task has two or more right answers."
  },
  "parsons": {
    id: "parsons",
    icon: parsonsTaskTypeIcon,
    note: "In this task you have to match moveable code lines with static numbers correctly. Drag and drop lines on right places."
  },
  "match": {
    id: "match",
    icon: matchTaskTypeIcon,
    note: "In this task you have to match moveable answers with static items correctly."
  },
  "wordpress": {
    id: "wordpress",
    icon: iframeTaskTypeIcon
  },
  "office365": {
    id: "office365",
    icon: iframeTaskTypeIcon
  },
  "code": {
    id: "code",
    icon: codeTaskTypeIcon
  }
}

export default taskTypes
