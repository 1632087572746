import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import {Box, IconButton, Typography} from "@mui/material"
import {makeStyles} from "@mui/styles"
import cn from "classnames"
import Overview, {OverviewProps} from "./components/Overview"
import React from "react"
import Files from "./components/Files"
import {CodeEditorFile} from "../../index"

interface Props {
  files?: CodeEditorFile[]
  overview?: OverviewProps
  isCollapsed?: boolean
  onCollapseToggle?: () => void
  onFileClick: (file: CodeEditorFile) => void
}

const useStyles = makeStyles(() => ({
  root: {
    position: "absolute",
    zIndex: "10",
    width: "15%",
    height: "100%",
    borderRight: "1px solid #353337",
    transition: ".3s",
    "&.overview": {
      width: "32%"
    },
    "&.collapsed": {
      width: 0,
      transition: ".3s"
    }
  },
  layout: {
    transition: ".1s",
    "&.collapsed": {
      opacity: 0,
      transform: "translateX(-30%)",
      transition: ".1s"
    }
  },
  collapse: {
    position: "absolute",
    bottom: 16,
    right: 16,
    transition: ".3s",
    "&.collapsed": {
      right: -44,
      transition: ".3s"
    }
  }
}))

export default function Sidebar({
  files,
  overview,
  isCollapsed,
  onCollapseToggle,
  onFileClick
}: Props) {
  const s = useStyles()

  return (
    <Box
      className={cn(s.root, {
        "overview": overview,
        "collapsed": isCollapsed
      })}>
      {files ? (
        <Box mt={1.5} mb={6} overflow="hidden" className={cn(s.layout, {"collapsed": isCollapsed})}>
          <Typography variant="h6" mb={1} px={3} color="gray">
            Files
          </Typography>
          <Files
            files={files}
            onFileClick={onFileClick}
          />
        </Box>
      ) : overview && (
        <Box overflow="hidden" className={cn(s.layout, {"collapsed": isCollapsed})}>
          <Overview {...overview}/>
        </Box>
      )}
      {onCollapseToggle && (
        <Box className={cn(s.collapse, {"collapsed": isCollapsed})}>
          <IconButton size="small" color={"white" as any} onClick={onCollapseToggle}>
            {isCollapsed ? <ArrowForwardIcon/> : <ArrowBackIcon/>}
          </IconButton>
        </Box>
      )}
    </Box>
  )
}
