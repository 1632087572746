import {gql} from "@apollo/client"

export default gql`
  query GetTestingSessionDetails($testingSessionId: GraphQLObjectId!) {
    getTestingSession(testingSessionId: $testingSessionId) {
      _id
      _score
      _isPassed
      _tasks {
        _id
        displayName
        description
        type
        multipleDetails {
          codeSnippet {
            code
            language
          }
          options {
            _id
            text
          }
        }
        answers {
          _id
          content
        }
        questions {
          _id
          content
          position
        }
        practicalDetails {
          compilerId
          compilerVersionId
          language
          template
          input
        }
      }
      givenAnswers {
        _id
        taskId
        score
        answered
        validated
        sourceCode
        answers {
          position
          id
        }
        correctAnswer {
          sourcecode
          answers {
            id
            position
          }
          clientValidation {
            result
          }
        }
      }
      status
      startDate
      createdAt
      user {
       _id
       fullName   
      }
      exam {
        _id
        displayName {
          value
        }
        displayCode
        code
        passingScoreRate
      }
      finishDate
      options {
        showResult
      }
      result {
        objectives {
          objectiveId
          score
          title
        }
      }
    }
  }
`
