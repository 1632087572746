import {Box, Theme} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import useEditor from "../hooks/useEditor"
import {CodeEditorFile} from "../"
import React from "react"

interface Props {
  file: CodeEditorFile
  onChange: (filename: string, value: string) => void
  onBlur?: () => void
  hideLineNumbers?: boolean
}

const useStyles = makeStyles<Theme>(() => ({
  root: {
    position: "relative",
    width: "100%",
    height: "100%"
  },
  layout: {
    position: "relative",
    width: "100%",
    height: "100%",
    padding: "10px"
  }
}))

export default function Editor({
  file,
  onChange,
  onBlur,
  hideLineNumbers
}: Props) {
  const s = useStyles()

  const {editorRef} = useEditor({
    lang: file.lang || file.filename.split(".").reverse()[0],
    input: file.content || " ",
    lineNumbers: !hideLineNumbers,
    onChange: value => onChange(file.filename, value),
    onBlur
  })

  return (
    <Box className={s.root}>
      <Box ref={editorRef} className={s.layout}/>
    </Box>
  )
}
