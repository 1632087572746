import apiCall from "api/rest"
import {useEffect, useState} from "react"

export default function useSphereEngine(
  id: string,
  compilerId: number,
  compilerVersionId: number
) {
  const [submissionId, setSubmissionId] = useState(null)
  const [compileAnswer, setCompileAnswer] = useState(null)
  const [runDisabled, setRunDisabled] = useState(false)

  useEffect(() => {
    setSubmissionId(null)
    setCompileAnswer(null)
    setRunDisabled(false)
  }, [id])

  useEffect(() => {
    if (!submissionId) return

    let intervalVar = null

    const retrieveSubmission = async () => {
      try {
        const res = await apiCall({
          url: `/sphere-engine/compilers/submissions/${submissionId}`,
          method: "GET"
        })

        setCompileAnswer(res?.data?.result)

        if (res?.data?.result?.status_code > 10) {
          clearInterval(intervalVar)
          setRunDisabled(false)
        }
      } catch (error) {
        setCompileAnswer({
          status_code: 11,
          status_name: "something went wrong..."
        })
        clearInterval(intervalVar)
        setRunDisabled(false)
      }
    }

    intervalVar = setInterval(retrieveSubmission, 2000)
  }, [submissionId])

  const onRunCode = async (code) => {
    setRunDisabled(true)
    setCompileAnswer({
      status_name: "waiting to be compiled..."
    })

    try {
      const res = await apiCall({
        url: `/sphere-engine/compilers/submissions/task/${id}`,
        method: "POST",
        data: {
          source: code || " ",
          compilerId: compilerId || "116",
          compilerVersionId: compilerVersionId
        }
      })

      if (!res?.data?.id) {
        setCompileAnswer({
          status_code: 11,
          status_name: res.data.message || "something went wrong..."
        })
      } else {
        setSubmissionId(res.data.id)
      }
    } catch (e) {
      setCompileAnswer({
        status_code: 11,
        status_name: "something went wrong..."
      })
      setRunDisabled(false)
    }
  }

  return {
    compileAnswer,
    runDisabled,
    onRunCode
  }
}
